.section-podcasts {
    margin-bottom: 15vh;
}

.podcast-img {
    max-width: 100px;
    max-height: 50px;
}

.podcast-item-title {
    font-family: 'Averi Italic';
    font-weight: bold;
    color: #E6750A;
    font-size: 1.8rem;
}

.podcast-item-author {
    font-family: 'Montserrat Bold Italic';
    color: #151515;
}

.podcast-item-duration {
    font-family: 'Montserrat Bold Italic';
    color: #151515;
    display: block;
    line-height: 0.5;
}

.btn-add {
    background: linear-gradient(180deg, #ECAE21 0%,#EA8717  100%);
    color: white;
    font-family: 'Montserrat Bold Italic';
    font-weight: bold;
    padding: 0.5;
    margin-bottom: 5vh;
}

.table-podcasts tr {
    padding: 2vh 0;
}
.table-podcasts td {
    vertical-align: middle;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer,
}

.dropzone-enter {
    border-color: #EA8717;
}

.imagen-selected {
    align-items: center;
    padding: 50px; 
    border: 1px solid #ECAE21;
    border-radius: 10px;
    max-width: 100%;
}

.imagen-selected img {
    max-height: 150px;
    max-height: 100%;
}

.stepper {
    width: 100%;
    text-align: center;
}

.form-input {
    text-align: left;
    margin-top: 5vh;
}

.form-input label, .field-name {
    font-family: 'Montserrat Bold Italic';
    color: #151515;
}

.field-name {
    margin-right: 10px;
}

.field-name-data, .form-group input {
    font-family: 'Montserrat Light';
    color: #353535;
    font-size: 0.9rem;
}

.info-audio {
    margin-top: 5vh;
}

.imagen-select {
    border: 1px solid #ECAE21;
    width: 150px;
    height: 150px;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    vertical-align: middle;
}

.imagen-select img {
    width: 100%;
    max-height: 100%;
}

@media only screen and (max-width: 600px){
    .podcast-item-title {
        font-size: 1.2rem;
    }
    
    .podcast-item-author {
        font-size: 0.8rem;
    }
}