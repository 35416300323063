.player-zone {
    width: 80%;
    margin: auto;
    background-color: #ededed;
}

.title-player {
    padding: 5vh;
    padding-top: 10vh;
}

.title-player img {
    max-width: 30%;
    max-height: 150px;
}

.title-podcasts {
    font-family: 'Averi Italic';
    font-weight: bold;
    color: #E6750A;
    font-size: 3rem;
    margin-top: 15px;
}

.subtitle-podcasts {
    font-family: 'Montserrat Bold Italic';
    color: black;
}

.control-player {
    width: 100%;
    background-color: #000;
    padding: 10px 5%;
}

.info-time {
    width: 100%;
    display: inline-block;
}
.palyer-time {
    float: left;
    font-family: 'Montserrat Light';
    font-weight: bold;
    color: #fff;
    font-size: 0.8rem;
    margin-bottom: 0;
}

.right {
    float: right;
}

.volumen-controls {
    display: inline-flex;
    width: 80%;
    float: left;
}

.volumen-controls img {
    cursor: pointer;
}
  
.volumen-controls span {
    margin: 18px 5px;
}

.more-controls {
    width: 100%;
    display: inline-block;
    text-align: center;
}

.play-controls {
    text-align: center;
    margin: auto;
    display: inline;
}

.play-controls img {
    width: 32px;
    height: 32px;
    margin: 0px 10px;
    cursor: pointer;
}

.prox-podcasts {
    background: linear-gradient(180deg, #EA8717 0%,#ECAE21  100%);
}

.prox-podcasts h4 {
    padding: 20px;
    color: white;
    font-family: 'Averi Italic';
}

@media only screen and (max-width: 600px){
    .player-zone {
        width: 100%;
    }

    .title-player img {
        max-width: 100%;
        width: 100%;
    }

    .title-podcasts {
        font-family: 'Averi Italic';
        font-weight: bold;
        color: #E6750A;
        font-size: 2rem;
        margin-top: 15px;
    }
    
    .subtitle-podcasts {
        font-family: 'Montserrat Bold Italic';
        color: black;
        font-size: 1rem;
    }

    .volumen-controls {
        margin: auto;
        float: unset;
    }
}