.card-login {
    width: 50%;
    margin: auto;
    margin-top: 60px; 
    padding-left: 60px; 
    padding-right: 60px;
}

.card-login .contact-title {
    width: 40%;
}

@media only screen and (max-width: 600px){
    .card-login {
        width: 90%;
        margin: auto;
        margin-top: 15vh; 
        padding-left: 10px; 
        padding-right: 10px;
    }

    .card-login .contact-title {
        width: 80%;
        font-size: 1.4rem;
    }
}