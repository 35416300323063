.ProgressBar {
    width: 100%;
    height: 8px;
    background-color: rgb(183, 155, 229);
    border-radius: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
  }
  
  .Progress {
    background-color: #EA8717;
    height: 100%;
    margin: 0;
    border-radius: 5px;
  }