svg {
    /*
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);*/
    margin-top: 5px;
  }
  
  path {
    fill: transparent;
  }
  
  .select__1 path:nth-child(-n+1) {
    fill: #FF9800;
  }
  
  .select__2 path:nth-child(-n+2) {
    fill: #FF9800;
  }
  
  .select__3 path:nth-child(-n+3) {
    fill: #FF9800;
  }
  
  .select__4 path:nth-child(-n+4) {
    fill: #FF9800;
  }
  
  .select__5 path:nth-child(-n+5) {
    fill: #FF9800;
  }
  
  .select__6 path:nth-child(-n+6) {
    fill: #FF9800;
  }
  
  .select__7 path:nth-child(-n+7) {
    fill: #FF9800;
  }
  
  .select__8 path:nth-child(-n+8) {
    fill: #FF9800;
  }
  
  .select__9 path:nth-child(-n+9) {
    fill: #FF9800;
  }
  
  .select__10 path:nth-child(-n+10) {
    fill: #FF9800;
  }
  
  .select__11 path:nth-child(-n+11) {
    fill: #FF9800;
  }
  
  .select__12 path:nth-child(-n+12) {
    fill: #FF9800;
  }