html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 25px;
  color: #444;
}

#background_wrap {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url('/img/fondo circuito1111.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.header {
  padding: 5px 0px;
  box-shadow: 0 0 15px rgba(0, 0, 0, .05);
  background-color: #fefefe;
}

.nav-logo{
    max-width: 150px;
    max-height: 100px;
}

.logo {
  width: 160px;
}
.header .navbar-light .navbar-nav .nav-link {
  color: #444;
  font-size: 18px;
  padding: 0 25px;
}
.header .navbar-light .navbar-nav .nav-link:hover {
  color: #FAA819;
}


.main-menu {
  text-align: center;
  margin-top: 30px;
}
.main-menu li {
  display: inline-block;
  padding: 20px;
}
.main-menu li a {
  font-size: 18px;
}

.section-padding {
  padding: 20px 0px;
}

.section-title {
  margin-bottom: 60px;
}

.header-section {
  margin-top: 0px;
}

.streaming-section {
  position: relative;
}

.streaming-header {
  font-family: 'Averi Italic';
  font-weight: bold;
  color: white;
  font-size: 1.3rem;
  background: transparent linear-gradient(180deg, #E6750A 0%, #ED9521 100%) 0% 0% no-repeat;
  padding: 0.4em 0.8em .4em 0.8em;
  transform: skew(-20deg);
  width: 15%;
  margin: auto;
}

.slider-radios {
  width: 50%;
  margin: 2vh auto;
}

.item-slider {
  opacity: 0.5;
  padding: 50px;
  width: 90% !important;
}

.item-slider.active {
  opacity: 1;
  padding: 10px;
  width: 90% !important;
}

.btn-play { 
  border-radius: 50%;
  width: 42px;
  height: 42px;
  background-color: #EC9121; 
  margin-top: 20px;
  padding: 0;
  margin-bottom: -5px;
}

.btn-play img {
  max-width: 100%;
}

.player-controls {
  display: inline-flex;
  width: 20%;
}

.player-controls span {
  margin: 18px 5px;
}

.section-title-news {
  margin-top: 20vh;
  margin-bottom: 60px;
}

.section-title-programation {
  margin-top: 20vh;
  margin-bottom: 0px;
}

.programation-up-section {
    /*background: linear-gradient(180deg, #ECAE21 0%,#EA8717  50%, transparent 50%);*/
    background-color: #000;
    padding: 50px 0px;
}

.news-up-section {
    background: linear-gradient(180deg, #EA8717 0%,#ECAE21  100%);
    background-color: #000;
    padding: 10vh 0px;
}

.img-news {
  margin: auto;
  min-height: 200px;
  width: 100%;
}

.news-slider {
  width: 80%;
  margin: auto;
}


.contact-section {
  padding: 50px 0px;
}

.contact-form-group {
  font-family: 'Montserrat Light';
  width: 80%;
  margin: auto;
}

.contact-form input, .contact-form textarea {
  width: 100%;
  padding: 10px 15px;
  font-size: 15px;
  margin-bottom: 30px;
  border: 1px solid #eee;
  border-radius: 2px;
  background: #FAFAFA 0% 0% no-repeat padding-box;
}

.contact-title {
  font-family: 'Averi Italic';
  font-weight: bold;
  color: white;
  font-size: 1.3rem;
  background: transparent linear-gradient(180deg, #E6750A 0%, #ED9521 100%) 0% 0% no-repeat;
  padding: 0.2em 0.8em .2em 0.8em;
  transform: skew(-20deg);
  width: 15%;
  margin: auto;
}

.contact-font {
  text-align: left;
}

.theme-btn {
  font-family: 'Montserrat Bold Italic';
  color: #fff;
  background: transparent linear-gradient(180deg, #EA8917 0%, #EBAB20 100%) 0% 0% no-repeat padding-box;
  border-radius: 0px;
  transform: skew(-20deg);
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.5rem 2rem;
}

.radio-select {
  width: 25%;
  display: block;
	font-size: 1.2rem;
	font-family: 'Averi Regular', sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: 0.5em 1.4em .2em 0.8em;
	max-width: 100%;
  margin: 10px auto 0px auto;
	border: 1px solid transparent;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	border-radius: .1em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fafafa;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%2300000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}

.radio-select::-ms-expand {
	display: none;
}
.radio-select:hover {
	border-color: #888;
}
.radio-select:focus {
	border-color: #aaa;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}
.radio-select option {
	font-weight:normal;
}

.player-controls img {
  margin: auto;
  cursor: pointer;
}

.separator {
  padding: 2px;
}

.right-contact {
  text-align: right;
}

.link-nav {
  font-family: 'Montserrat Light';
  margin: 0px 10px;
  color: black !important;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

.banner {
  width: 35%;
  margin: 13vh auto;
}

.banner-title {
  font-family: 'Averi Italic';
  font-style: italic;
  font-size: 4.5rem;
  text-align: left;
  color: #E77C0F;
  font-weight: bold;
}

.banner-title-sub {
  font-family: 'Averi Italic';
  font-style: italic;
  font-size: 4.5rem;
  text-align: right;
  color: #EC921E;
  font-weight: bold;
}

.slick-dots{
  position: absolute;
  bottom: 20px;
  display: block;
  width: 100%;
  padding: 10;
  margin: 0;
  list-style: none;
  text-align: center;
  }
  
  .item-img-news {
    vertical-align: middle;
    max-width: 100%;
    position: relative;
  }
  
  .news-slider .slick-slider {
      background: #000;
  }

  .slick-dots li button:before {
    color: #fff;
  }

  .slick-dots li.slick-active button:before{
    font-size:14px;
    color:#EC8422;
  }

  .news-slider .slick-prev{ 
    left: 25px;
    opacity: 1;
  }
  .news-slider .slick-next{ 
    right: 30px;
    opacity: 1;
  }

  .news-slider .slick-prev::before, .news-slider .slick-next::before {
    color:#EA8917;
    font-size: 30px;
  }

  .slick-prev, .slick-next {
    z-index: 1029;
  }

  .icons-socials {
    width: 42px;
    height: 42px;
    margin: 10px;
  }

  #equalizer_canvas {
    width: 30%;
    margin: auto;
    max-height: 120px;
  }

  .banner-mk {
    width: 60%;
    margin: auto;
    margin-bottom: 20px;
  }

  .banner-mk img {
    height: 100px;
    width: 100%;
  }

  .error-stream {
    margin: 10px 0px;
    color: rgb(165, 29, 29);
    margin: auto;
    font-size: 1rem;
  }

  .label-banner {
    width: 60%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0px 10px;
    text-align: right;
  }

  .label-banner-title {
    font-family: 'Averi Italic';
    font-weight: bold;
    color: white;
    font-size: 1.3rem;
    background: transparent linear-gradient(180deg, #E6750A 0%, #ED9521 100%) 0% 0% no-repeat;
    padding: 0.2em 0.8em .2em 0.8em;
    transform: skew(-20deg);
    margin: 5px auto;
    display: inline-block;
  }

  .link-banner {
    text-decoration: none;
    color: #e1e1e1;
    font-family: 'Montserrat Light';
    font-size: 0.9rem;
    padding-right: 0.5rem;
  }

  a.link-banner:hover {
    text-decoration: none;
    color: #c1c1c1;
  }

  .right-contact p a {
    text-decoration: none;
    color: #444;
  }

  .right-contact p a:hover {
    text-decoration: none;
    color: #005;
  }
