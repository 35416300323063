  .track-player input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    background: rgba(255,255,255,0.0)
  }
  .track-player input[type=range]:focus {
    outline: none;
  }


  .track-player input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    background: #fff;
    border-radius: 1.3px;
    border: 0.2px solid #fff;
  }
  .track-player input[type=range]::-webkit-slider-thumb {
    height: 16px;
    width: 16px;
    border: none;
    border-radius: 50%;
    background: #EC9121;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
  }

  .track-player input[type=range]::-webkit-slider-thumb::before {
    background-color: #ECAE21;
    height: 6px;
  }

  .track-player input[type=range]:focus::-webkit-slider-runnable-track {
    background: #fff;
  }

  .track-player input[type=range]:-moz-focusring{
    outline: 1px solid #000;
    outline-offset: -1px;
  }
  
  .track-player input[type=range]::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    background: #fff;
    border-radius: 1.3px;
  }
  .track-player input[type=range]::-moz-range-thumb {
    height: 16px;
    width: 16px;
    border: none;
    border-radius: 50%;
    background: #EC9121;
    cursor: pointer;
  }

  .track-player input[type=range]::-moz-range-progress {
    background-color: #ECAE21;
    height: 6px;
  }

  .track-player input[type=range]::-ms-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  .track-player input[type=range]::-ms-fill-lower {
    background: #ECAE21;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  .track-player input[type=range]::-ms-fill-upper {
    background: #fff;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  .track-player input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #EC9121;
    cursor: pointer;
    margin-top: -6px;
  }

  



  .volumen-controls input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    background: rgba(255,255,255,0.0)
  }
  .volumen-controls input[type=range]:focus {
    outline: none;
  }

  .volumen-controls input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: #fff;
    border-radius: 1.3px;
    border: 0.2px solid #fff;
  }
  .volumen-controls input[type=range]::-webkit-slider-thumb {
    height: 12px;
    width: 12px;
    border: none;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
  }
  .volumen-controls input[type=range]:focus::-webkit-slider-runnable-track {
    background: #fff;
  }

  .volumen-controls input[type=range]:-moz-focusring{
    outline: 1px solid #000;
    outline-offset: -1px;
  }
  
  .volumen-controls input[type=range]::-moz-range-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: #fff;
    border-radius: 1.3px;
  }
  .volumen-controls input[type=range]::-moz-range-thumb {
    height: 12px;
    width: 12px;
    border: none;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  }
  .volumen-controls input[type=range]::-ms-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  .volumen-controls input[type=range]::-ms-fill-lower {
    background: #fff;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  .volumen-controls input[type=range]::-ms-fill-upper {
    background: #fff;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  .volumen-controls input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: none;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    margin-top: -6px;
  }


  /* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1800px) {
  .track-player input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
  }
  .track-player input[type=range]::-webkit-slider-thumb {
    height: 24px;
    width: 24px;
  }

  .track-player input[type=range]::-moz-range-track {
    width: 100%;
    height: 6px;
  }
  .track-player input[type=range]::-moz-range-thumb {
    height: 24px;
    width: 24px;
  }
  .track-player input[type=range]::-ms-track {
    width: 100%;
    height: 6px;
  }

  .track-player input[type=range]::-ms-thumb {
    height: 20px;
    width: 20px;
  }
}