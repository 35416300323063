  .streaming-section input[type=range] {
    -webkit-appearance: none;
    margin: 18px 0;
    width: 100%;
    background: rgba(0,0,0,0.0)
  }
  .streaming-section input[type=range]:focus {
    outline: none;
  }


  .streaming-section input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: #000;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }
  .streaming-section input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    height: 12px;
    width: 12px;
    border: none;
    border-radius: 50%;
    background: #EC9121;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
  }
  .streaming-section input[type=range]:focus::-webkit-slider-runnable-track {
    background: #000;
  }

  .streaming-section input[type=range]:-moz-focusring{
    outline: 1px solid white;
    outline-offset: -1px;
  }
  
  .streaming-section input[type=range]::-moz-range-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: #000;
    border-radius: 1.3px;
  }
  .streaming-section input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    height: 12px;
    width: 12px;
    border: none;
    border-radius: 50%;
    background: #EC9121;
    cursor: pointer;
    margin-top: -6px;
  }
  .streaming-section input[type=range]::-ms-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  .streaming-section input[type=range]::-ms-fill-lower {
    background: #000;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  .streaming-section input[type=range]::-ms-fill-upper {
    background: #000;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  .streaming-section input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: none;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #EC9121;
    cursor: pointer;
    margin-top: -6px;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1800px) {
  .streaming-section input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
  }
  .streaming-section input[type=range]::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
  }

  .streaming-section input[type=range]::-moz-range-track {
    width: 100%;
    height: 6px;
  }
  .streaming-section input[type=range]::-moz-range-thumb {
    height: 20px;
    width: 20px;
  }
  .streaming-section input[type=range]::-ms-track {
    width: 100%;
    height: 6px;
  }

  .streaming-section input[type=range]::-ms-thumb {
    height: 20px;
    width: 20px;
  }
}