/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px){
    body {
        background-size: 100% auto;
        background-position: right 0px top 80px;
    }

    #background_wrap {
        background-size: 100% auto;
        background-position: right 0px top 80px;
    }


    .item-slider {
        opacity: 0.5;
        padding: 10px;
    }
      
    .item-slider.active {
        opacity: 1;
        padding: 0px;
    }

    .radios-img {
        width: 100%;
        margin: auto;
    }

    .streaming-header {
        width: 60%;
        font-size: 1.4rem;
    }

    .contact-title {
        width: 60%;
        font-size: 1.4rem;
    }

    .radio-select {
        width: 70%;
    }

    .slider-radios {
        width: 100%;
    }

    .slick-slide {
        width: 100px;
    }

    .big-streaming h1 {
        font-size: 3rem;
    }

    .separator {
        display: none;
    }

    .separator + svg {
        display: none;
    }

    .contact-section {
        padding: 50px 10px;
    }

    .contact-form-group {
        width: 100%;
    }
    
    .player-controls {
        width: 60%;
    }

    .btn-play { 
        margin-bottom: 5px;
    }

    .right-contact {
        margin-top: 10vh;
        text-align: center;
    }

    .link-nav {
        font-size: 0.6rem;
        margin: 0px 3px;
    }

    .banner {
        width: 80%;
        margin: 8vh auto;
    }
      
    .banner-title {
        font-size: 2.6rem;
        text-align: left;
        color: #EA8917;
        font-weight: bold;
    }
    
    .banner-title-sub {
        font-size: 2.6rem;
        text-align: right;
        color: #EBAB20;
        font-weight: bold;
    }

    .section-title-news {
        margin-top: 10vh;
    }

    .news-slider {
        width: 100%;
        margin: auto;
    }

    #equalizer_canvas {
        width: 70%;
        margin: auto;
    }

    .banner-mk {
        width: 90%;
    }

    .error-stream {
        font-size: 0.8rem;
    }

    .label-banner {
        width: 60%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0px 10px;
        text-align: right;
      }
    
    .label-banner-title {
        font-size: 0.6rem;
        padding: 0.1em 0.8em .1em 0.8em;
        margin: 0px auto;
    }

    .label-banner {
        padding-right: 0px;
    }

    .img-news {
        margin: auto;
        min-height: 100px;
        max-height: 400px;
    }

    .link-banner {
        font-size: 0.4rem;
        padding-right: 0.5rem;
    }

    .slick-dots {
        bottom: 5px;
    }

    .news-slider .slick-prev{ 
        left: 0px;
        opacity: 1;
    }
    .news-slider .slick-next{ 
        right: 2px;
        opacity: 1;
    }

    .news-slider .slick-prev::before, .news-slider .slick-next::before {
        color:#EA8917;
        font-size: 20px;
    }
} 

/* Small devices (portrait tablets and large phones, 600px and up) 
@media only screen and (min-width: 600px) {}*/

/* Medium devices (landscape tablets, 768px and up)*/
@media only screen and (min-width: 600px) and (max-width: 850px) {
    .item-slider {
        opacity: 0.5;
        padding: 10px;
    }
      
    .item-slider.active {
        opacity: 1;
        padding: 0px;
    }

    .radios-img {
        width: 100%;
        margin: auto;
    }

    .streaming-header, .contact-title {
        width: 30%;
        font-size: 1.5rem;
    }

    .radio-select {
        width: 50%;
    }

    .slider-radios {
        width: 60%;
    }

    .slick-slide {
        width: 100px;
    }

    .big-streaming h1 {
        font-size: 3rem;
    }

    .separator {
        display: none;
    }

    .separator + svg {
        display: none;
    }

    .contact-section {
        padding: 50px 10px;
    }

    .contact-form-group {
        width: 100%;
    }
    
    .player-controls {
        width: 50%;
    }

    .btn-play { 
        margin-bottom: 5px;
    }

    .right-contact {
        margin-top: 10vh;
        text-align: center;
    }

    .banner {
        width: 40%;
        margin: 8vh auto;
    }
      
    .banner-title {
        font-size: 3rem;
        text-align: left;
        color: #EA8917;
        font-weight: bold;
    }
    
    .banner-title-sub {
        font-size: 2.6rem;
        text-align: right;
        color: #EBAB20;
        font-weight: bold;
    }

    .section-title-news {
        margin-top: 10vh;
    }

    .news-slider {
        width: 100%;
        margin: auto;
    }

    #equalizer_canvas {
        width: 60%;
        margin: auto;
    }

    .banner-mk {
        width: 80%;
    }

    .error-stream {
        font-size: 0.8rem;
    }

    .label-banner {
        width: 60%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0px 10px;
        text-align: right;
      }
    
    .label-banner-title {
        font-size: 1rem;
        padding: 0.1em 0.8em .1em 0.8em;
        margin: 0px auto;
    }

    .label-banner {
        padding-right: 0px;
    }

    .img-news {
        margin: auto;
        min-height: 100px;
        max-height: 400px;
    }

    .link-banner {
        font-size: 0.6rem;
        padding-right: 0.5rem;
    }

    .slick-dots {
        bottom: 5px;
    }

    .slick-dots li button::before, .slick-dots li button {
        width: 10px;
        height: 10px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1800px) {
    .nav-logo {
        max-height: 200px;
    }
    .radios-img {
        width: 80%;
        margin: auto;
    }

    .link-nav {
        font-size: 1.2rem;
    }

    .banner-title, .banner-title-sub {
        font-size: 7rem;
    }

    .contact-title, .streaming-header {
        font-size: 2rem;
        padding: 0.5em 1em;
        width: 15%;
        margin-bottom: 60px;
    }

    .btn-play { 
        width: 64px;
        height: 64px;
    }

    .news-slider {
        padding: 10vh 0;
        width: 70%;
    }

    .contact-form-group {
        width: 70%;
    }

    .img-news {
        width: 80%;
        margin: auto;
        max-height: 600px;
    }

    .contact-form-group {
        font-size: 1.6rem;
    }

    .contact-form {
        margin-top: 20px;
    }

    .contact-form input, .contact-form textarea {
        font-size: 1.6rem;
        padding: 1rem;
    }

    .theme-btn {
        font-size: 1.5rem;
    }

    .icons-socials {
        width: 64px;
        height: 64px;
    }

    #equalizer_canvas {
        max-height: 200px;
    }

    .banner-mk {
        margin: 60px auto;
    }

    .label-banner-title {
        font-size: 1.6rem;
    }

    .label-banner {
        padding-right: 50px;
    }
}